<template>
  <b-modal
    ref="modal-brands-contact"
    modal-class="modal-default modal-brands-contact"
    size="xl"
    title="BootstrapVue"
    hide-footer
  >
    <template v-slot:modal-header="{ close }">
      <h5 class="modal-title">{{ $t("form.brands.getMediaKit") }}</h5>
      <b-link class="modal-close" @click="close()">
        <b-img
          :src="`${publicPath}assets/images/modal-close.png`"
          alt=""
        ></b-img>
      </b-link>
    </template>
    <template v-slot:default>
      <div class="contact-modal-content">
        <ValidationObserver ref="formBrandsContactUs" v-slot="{ handleSubmit }">
          <b-form @submit.prevent="handleSubmit(onSubmit)">
            <b-row>
              <b-col sm="12" md="6">
                <b-form-group
                  id="input-group-full-name"
                  :label="$t('form.brands.fullName')"
                  label-for="input-full-name"
                >
                  <ValidationProvider
                    rules="required"
                    v-slot="{ errors }"
                    :name="$t('form.brands.fullName')"
                  >
                    <b-form-input
                      id="input-full-name"
                      v-model="form.fullName"
                      type="text"
                    ></b-form-input>
                    <span v-if="errors[0]" class="label-error">{{
                      errors[0]
                    }}</span>
                  </ValidationProvider>
                </b-form-group>

                <b-form-group
                  id="input-group-company"
                  :label="$t('form.brands.company')"
                  label-for="input-company"
                >
                  <ValidationProvider
                    rules="required"
                    v-slot="{ errors }"
                    :name="$t('form.brands.company')"
                  >
                    <b-form-input
                      id="input-company"
                      v-model="form.company"
                      type="text"
                    ></b-form-input>
                    <span v-if="errors[0]" class="label-error">{{
                      errors[0]
                    }}</span>
                  </ValidationProvider>
                </b-form-group>

                <b-form-group
                  id="input-group-company-email"
                  :label="$t('form.brands.companyEmail')"
                  label-for="input-company-email"
                >
                  <ValidationProvider
                    rules="required|email"
                    v-slot="{ errors }"
                    :name="$t('form.brands.companyEmail')"
                  >
                    <b-form-input
                      id="input-company-email"
                      v-model="form.companyEmail"
                      type="text"
                    ></b-form-input>
                    <span v-if="errors[0]" class="label-error">{{
                      errors[0]
                    }}</span>
                  </ValidationProvider>
                </b-form-group>
              </b-col>
              <b-col sm="12" md="6">
                <b-form-group
                  id="input-group-your-message"
                  :label="$t('form.brands.tellUsAbout')"
                  label-for="input-your-message"
                >
                  <ValidationProvider
                    rules="required"
                    v-slot="{ errors }"
                    :name="$t('form.brands.tellUsAbout')"
                  >
                    <b-form-textarea
                      id="input-your-message"
                      v-model="form.yourMessage"
                      rows="9"
                      max-rows="12"
                    ></b-form-textarea>
                    <span v-if="errors[0]" class="label-error">{{
                      errors[0]
                    }}</span>
                  </ValidationProvider>
                </b-form-group>
              </b-col>
            </b-row>
            <div class="btn-submit-wrap form-group text-right">
              <button
                type="submit"
                class="btn btn-submit ml-auto"
                :disabled="!allowSubmit"
              >
                {{ $t("form.brands.getMyCopy") }}
              </button>
            </div>
          </b-form>
        </ValidationObserver>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
export default {
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data() {
    return {
      form: {
        fullName: "",
        company: "",
        companyEmail: "",
        yourMessage: ""
      },
      allowSubmit: true
    };
  },
  mounted() {
    this.$eventHub.$on("modalBrandsContactOpen", () => {
      this.$refs["modal-brands-contact"].show();
    });
  },
  beforeDestroy() {
    this.$eventHub.$off("modalBrandsContactOpen");
  },
  methods: {
    onSubmit() {
      this.$refs.formBrandsContactUs.validate().then(success => {
        if (!success) {
          return;
        }

        this.allowSubmit = false;

        let message = `${this.$t("form.brands.fullName")}: ${
          this.form.fullName
        }\n`;
        message += `${this.$t("form.brands.company")}: ${this.form.company}\n`;
        message += `${this.$t("form.brands.companyEmail")}: ${
          this.form.companyEmail
        }\n`;
        message += `Language: ${this.$i18n.locale === "mx" ? "ES" : "EN"}\n\n`;
        message += this.form.yourMessage;

        const data = {
          name: this.form.fullName,
          email: this.form.companyEmail,
          subject: "Website Contact: Brands",
          message: message,
          sendTo: "hello@trebelmusic.com",
          cc: "camila@trebel.io"
        };

        this.$axios
          .post(process.env.VUE_APP_SEND_EMAIL_URL, this.$qs.stringify(data), {
            headers: { "Content-Type": "application/x-www-form-urlencoded" }
          })
          .then(() => {
            this.allowSubmit = true;
            this.$refs["modal-brands-contact"].hide();
            this.$swal({
              customClass: {
                cancelButton: "btn-submit",
                container: "swal2-custom swal2-custom-success"
              },
              buttonsStyling: false,
              showCloseButton: true,
              showCancelButton: true,
              showConfirmButton: false,
              title: this.$t("form.brands.great"),
              text: this.$t("form.brands.weWillEmailYouACopy"),
              cancelButtonText: this.$t("form.brands.close")
            });

            this.form.fullName = this.form.company = this.form.companyEmail = this.form.yourMessage =
              "";

            this.$nextTick(() => {
              this.$refs.formBrandsContactUs.reset();
            });
          })
          .catch(() => {
            this.allowSubmit = true;
          });
      });
    }
  }
};
</script>
