<template>
  <div>
    <section class="brands-head">
      <div class="brands-head-inner">
        <div
          class="brands-head-bg"
          :style="{
            'background-image':
              'url(' + `${publicPath}assets/images/brands-head-bg.png` + ')'
          }"
        ></div>
        <div
          class="mobile-brands-head-bg"
          :style="{
            'background-image':
              'url(' +
              `${publicPath}assets/images/mobile-brands-head-bg.png` +
              ')'
          }"
        ></div>
        <div class="container">
          <h1
            class="head-title"
            v-html="$t('brandsPage.reachYourConsumers')"
          ></h1>
          <div class="brands-head-content d-flex align-items-center">
            <b-link class="brands-head-btn" @click="openModal">
              {{ $t("brandsPage.getMediaKit") }}
            </b-link>
          </div>
        </div>
      </div>
    </section>
    <section class="brands-slider">
      <div v-swiper:mySwiper="swiperBrandsOptions">
        <div class="swiper-wrapper">
          <div
            class="swiper-slide"
            v-for="(slide, index) in slides"
            :key="index"
          >
            <div class="container">
              <b-img
                :src="
                  `${publicPath}assets/images/brands/${$i18n.locale}/${slide}`
                "
                alt=""
                fluid
              ></b-img>
            </div>
          </div>
        </div>
        <div class="swiper-button-wrap container">
          <div
            class="brands-swiper-button-prev swiper-button-prev"
            :style="{
              'background-image':
                'url(' + `${publicPath}assets/images/prev.png` + ')'
            }"
          ></div>
          <div
            class="brands-swiper-button-next swiper-button-next"
            :style="{
              'background-image':
                'url(' + `${publicPath}assets/images/next.png` + ')'
            }"
          ></div>
        </div>
      </div>
    </section>
    <ModalBrandsContact></ModalBrandsContact>
  </div>
</template>

<script>
import ModalBrandsContact from "@/components/Modal/ModalBrandsContact.vue";
import { directive } from "vue-awesome-swiper";

import "swiper/css/swiper.css";

export default {
  name: "Brands",
  metaInfo() {
    return {
      title: this.$t("meta.brands.title"),
      titleTemplate: null,
      htmlAttrs: {
        lang: this.$t("lang")
      },
      meta: this.generateMeta({
        title: this.$t("meta.brands.title"),
        description: this.$t("meta.brands.description"),
        url: this.generateUrl(this.locale)
      })
    };
  },
  components: {
    ModalBrandsContact
  },
  directives: {
    swiper: directive
  },
  data() {
    return {
      swiperBrandsOptions: {
        navigation: {
          nextEl: ".brands-swiper-button-next",
          prevEl: ".brands-swiper-button-prev"
        }
      },
      slides: this.$t("brandsPage.slides")
    };
  },
  computed: {
    locale() {
      return this.$store.getters.locale;
    }
  },
  methods: {
    openModal() {
      this.$eventHub.$emit("modalBrandsContactOpen");
    }
  }
};
</script>

<style></style>
