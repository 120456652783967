var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('section',{staticClass:"brands-head"},[_c('div',{staticClass:"brands-head-inner"},[_c('div',{staticClass:"brands-head-bg",style:({
          'background-image':
            'url(' + _vm.publicPath + "assets/images/brands-head-bg.png" + ')'
        })}),_c('div',{staticClass:"mobile-brands-head-bg",style:({
          'background-image':
            'url(' +
            _vm.publicPath + "assets/images/mobile-brands-head-bg.png" +
            ')'
        })}),_c('div',{staticClass:"container"},[_c('h1',{staticClass:"head-title",domProps:{"innerHTML":_vm._s(_vm.$t('brandsPage.reachYourConsumers'))}}),_c('div',{staticClass:"brands-head-content d-flex align-items-center"},[_c('b-link',{staticClass:"brands-head-btn",on:{"click":_vm.openModal}},[_vm._v(" "+_vm._s(_vm.$t("brandsPage.getMediaKit"))+" ")])],1)])])]),_c('section',{staticClass:"brands-slider"},[_c('div',{directives:[{name:"swiper",rawName:"v-swiper:mySwiper",value:(_vm.swiperBrandsOptions),expression:"swiperBrandsOptions",arg:"mySwiper"}]},[_c('div',{staticClass:"swiper-wrapper"},_vm._l((_vm.slides),function(slide,index){return _c('div',{key:index,staticClass:"swiper-slide"},[_c('div',{staticClass:"container"},[_c('b-img',{attrs:{"src":(_vm.publicPath + "assets/images/brands/" + (_vm.$i18n.locale) + "/" + slide),"alt":"","fluid":""}})],1)])}),0),_c('div',{staticClass:"swiper-button-wrap container"},[_c('div',{staticClass:"brands-swiper-button-prev swiper-button-prev",style:({
            'background-image':
              'url(' + _vm.publicPath + "assets/images/prev.png" + ')'
          })}),_c('div',{staticClass:"brands-swiper-button-next swiper-button-next",style:({
            'background-image':
              'url(' + _vm.publicPath + "assets/images/next.png" + ')'
          })})])])]),_c('ModalBrandsContact')],1)}
var staticRenderFns = []

export { render, staticRenderFns }