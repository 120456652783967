var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"modal-brands-contact",attrs:{"modal-class":"modal-default modal-brands-contact","size":"xl","title":"BootstrapVue","hide-footer":""},scopedSlots:_vm._u([{key:"modal-header",fn:function(ref){
var close = ref.close;
return [_c('h5',{staticClass:"modal-title"},[_vm._v(_vm._s(_vm.$t("form.brands.getMediaKit")))]),_c('b-link',{staticClass:"modal-close",on:{"click":function($event){return close()}}},[_c('b-img',{attrs:{"src":(_vm.publicPath + "assets/images/modal-close.png"),"alt":""}})],1)]}},{key:"default",fn:function(){return [_c('div',{staticClass:"contact-modal-content"},[_c('ValidationObserver',{ref:"formBrandsContactUs",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('b-row',[_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('b-form-group',{attrs:{"id":"input-group-full-name","label":_vm.$t('form.brands.fullName'),"label-for":"input-full-name"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":_vm.$t('form.brands.fullName')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"input-full-name","type":"text"},model:{value:(_vm.form.fullName),callback:function ($$v) {_vm.$set(_vm.form, "fullName", $$v)},expression:"form.fullName"}}),(errors[0])?_c('span',{staticClass:"label-error"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_c('b-form-group',{attrs:{"id":"input-group-company","label":_vm.$t('form.brands.company'),"label-for":"input-company"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":_vm.$t('form.brands.company')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"input-company","type":"text"},model:{value:(_vm.form.company),callback:function ($$v) {_vm.$set(_vm.form, "company", $$v)},expression:"form.company"}}),(errors[0])?_c('span',{staticClass:"label-error"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_c('b-form-group',{attrs:{"id":"input-group-company-email","label":_vm.$t('form.brands.companyEmail'),"label-for":"input-company-email"}},[_c('ValidationProvider',{attrs:{"rules":"required|email","name":_vm.$t('form.brands.companyEmail')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"input-company-email","type":"text"},model:{value:(_vm.form.companyEmail),callback:function ($$v) {_vm.$set(_vm.form, "companyEmail", $$v)},expression:"form.companyEmail"}}),(errors[0])?_c('span',{staticClass:"label-error"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1)],1),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('b-form-group',{attrs:{"id":"input-group-your-message","label":_vm.$t('form.brands.tellUsAbout'),"label-for":"input-your-message"}},[_c('ValidationProvider',{attrs:{"rules":"required","name":_vm.$t('form.brands.tellUsAbout')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"input-your-message","rows":"9","max-rows":"12"},model:{value:(_vm.form.yourMessage),callback:function ($$v) {_vm.$set(_vm.form, "yourMessage", $$v)},expression:"form.yourMessage"}}),(errors[0])?_c('span',{staticClass:"label-error"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1)],1)],1),_c('div',{staticClass:"btn-submit-wrap form-group text-right"},[_c('button',{staticClass:"btn btn-submit ml-auto",attrs:{"type":"submit","disabled":!_vm.allowSubmit}},[_vm._v(" "+_vm._s(_vm.$t("form.brands.getMyCopy"))+" ")])])],1)]}}])})],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }